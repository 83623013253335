// Variables
$primary: #2c2c2c;
$secondary: #383838;
$qfBorderRadius: 10px;

// General Styling
html {
    color: $secondary;
    font-family: "Montserrat", sans-serif;
}

.v-main {
    min-height: 100vh;

    > .v-main__wrap {
        display: flex;
    }
}

.dashboard-card {
    .dashboard-menu-icon {
        font-size: 40px !important;
    }
}

//header styles
.v-app-bar {
    background: #fbfbfb;

    .header-logo .logo {
        background-image: url(/images/Sellier_Logo.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
    }

    .notification {
        i {
            background-color: #ffffff;
            filter: drop-shadow(0px 2px 5px #26334d08);
            border-radius: 30px;
            padding: 15px;
            color: $primary;
        }
    }
}

//Nav Drawer
.v-navigation-drawer {
    font-size: 14px;
    background-color: #ebebeb !important;

    .v-menu__content {
        border-radius: $qfBorderRadius;
        filter: drop-shadow(0px 2px 5px #26334d08);
        box-shadow: none;

        .v-list:hover {
            background-color: $primary;

            .v-list-item__title {
                color: #ffffff;
            }
        }
    }

    .v-navigation-drawer__content {
        position: relative;

        .v-list {
            display: flex;
            margin-top: 53px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                min-height: 555px;
                background: #ebebeb;
            }

            .v-list-item.v-list-item--active:not(.sub-menu-item) {
                &:before {
                    background-color: $primary;
                }

                .v-list-item__title,
                i {
                    color: #ffffff !important;
                }
            }

            .v-list-item__title {
                font-size: 14px;
            }

            .v-list-item {
                width: 280px;
                margin-bottom: 16px;
                padding: 0;

                &:before {
                    background-color: #ffffff;
                    border-radius: $qfBorderRadius;
                    opacity: 1;
                }

                &.sub-menu-item {
                    padding-left: 55px;
                    min-height: 25px;

                    &:before {
                        background-color: transparent;
                        border-radius: $qfBorderRadius;
                        opacity: 1;
                    }

                    &.v-list-item--active {
                        font-weight: 600;
                        color: $primary;
                    }
                }

                .v-list-item__title,
                i {
                    color: $primary !important;
                    z-index: 1;
                }

                .v-list-item__icon,
                .v-list-item__icon {
                    margin: 18px 20px 18px 0;
                    padding-left: 21px;
                }

                .v-list-item__content {
                    z-index: 1;
                }
            }
        }
    }
    .v-navigation-drawer__append {
        text-align: center;

        .v-list-item {
            min-height: 20px;

            &.v-list-item--link {
                &::before {
                    content: none;
                }

                &:hover {
                    background-color: transparent;
                }

                .v-list-item__title {
                    text-decoration: underline;
                    font-size: 14px;
                }
            }
            .sellier {
                span {
                    font-size: 12px;
                    color: $primary;
                    opacity: 20%;
                }
            }
        }
    }
}

// Form Styles
.v-form {
    .forgot-password {
        text-align: right;

        a {
            text-decoration: none;
            font-size: 12px;
            line-height: 15px;
        }
    }
}

.v-dialog {
    label {
        font-size: 14px;
        font-weight: 600;
        color: $secondary;
    }
    textarea {
        line-height: 1.5rem !important;
    }
}

.v-main.login-remember {
    background: #f7f7f7;

    .container--fluid {
        justify-content: center;
        align-items: center;
    }

    .v-form {
        position: absolute;
        background: white;
        max-width: 430px;
        width: 100%;
        z-index: 10;
    }
}

// Search Styles
.search {
    .v-input__slot {
        display: flex;
        flex-direction: row-reverse;
    }

    &.table-search {
        filter: drop-shadow(0px 2px 5px #26334d08);
    }

    &.table-search {
        border-radius: $qfBorderRadius;

        .v-text-field__slot {
            margin-left: 15px;
        }

        .v-input__control {
            min-height: 40px;

            label {
                color: $primary;
                opacity: 50%;
                font-size: 12px;
                font-weight: 500;
            }

            i {
                font-size: 17px;
                color: $primary;
            }
        }
    }
}

// Table Styles
.v-data-table {
    .v-toolbar__content {
        padding: 0 !important;
    }
}

.v-data-table {
    margin-bottom: 30px;

    table {
        padding: 35px 28px;

        .v-data-table-header {
            th {
                > span {
                    font-size: 12px;
                    line-height: 20px;
                }

                .v-icon {
                    float: right;
                }
            }
        }

        tr {
            th:first-child,
            td:first-child {
                padding: 0 !important;
            }

            td {
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                padding: 10px 15px !important;
            }

            .v-icon {
                font-size: 18px !important;
            }

            &.v-data-table__expanded__row {
                td {
                    border-bottom: 0 !important;
                    border-right: 0 !important;
                    border-left: 0 !important;
                }
            }

            &.v-data-table__expanded__content {
                box-shadow: none !important;
            }
        }

        .v-data-table__expanded {
            .expanded-details {
                .row {
                    div {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }

                    div:not(:last-child) {
                        position: relative;
                    }
                }
            }

            span {
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
            }
        }
    }
}

.v-expansion-panel-content {
    padding: 10px 25px 30px;

    ul {
        padding-left: 0;

        li {
            list-style: none;
        }
    }
    > div {
        padding: 0;
    }
    table {
        padding: 0;
    }
}

.qf-card-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .qf-card-title {
        padding: ($qfBorderRadius*2) $qfBorderRadius $qfBorderRadius;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1em;
        flex-shrink: 0;
    }
    .qf-card {
        flex-grow: 1;
        &.qf-card-style-default {
            border-radius: $qfBorderRadius;
            background-color: #FFF;

            .qf-card-header, .qf-card-body, .qf-card-footer {
                padding: $qfBorderRadius*2;

                &:not(:first-child) {
                    border-top: solid 3px #F7F8FA;
                }
            }
        }
    }
}

.qf-card {
    .v-tabs {
        .v-slide-group__wrapper {
            padding: 0;
            margin-bottom: -$qfBorderRadius*2;
        }
    }

    .v-slide-group__wrapper {
        padding: 0 40px;

        .v-slide-group__content {
            justify-content: space-evenly;

            .v-tab {
                font-size: 12px;
                padding: 0;
                min-width: auto;
            }
        }
    }

    .item-total {
        border-radius: $qfBorderRadius;
        text-align: center;
        padding: $qfBorderRadius*4 $qfBorderRadius*2;

        &.item-rev {
            background-color: #acacac1a;
            margin-bottom: 5px;
            border-radius: 10px 10px 0 0;
        }

        &.item-com {
            background-color: #d3d3d3;
            border-radius: 0 0 10px 10px;
        }

        .item-total-value {
            font-size: 25px;
            line-height: 25px;
            font-weight: 800;
            padding-bottom: 5px;
            color: $secondary;
            font-family: "Montserrat", sans-serif;
        }

        .item-total-label {
            font-size: 10px;
            font-weight: 700;
            color: $secondary;
            font-family: "Montserrat", sans-serif;
            text-transform: uppercase;
        }
    }

    .orders-block-item {
        display: block;
        background-color: #fafbfc;
        border-radius: $qfBorderRadius;
        padding: $qfBorderRadius*2;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        text-decoration: inherit;

        .orders-block-item-value {
            font-size: 30px;
            line-height: 50px;
            font-weight: 500;
            padding-bottom: 5px;
            color: #2c2c2c;
        }

        .orders-block-item-label {
            font-size: 10px;
            font-weight: 700;
            font-family: "Montserrat", sans-serif;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.6);
        }

        &:link:hover {
            background-color: $primary;
            .orders-block-item-value, .orders-block-item-label  {
                color: #f7f7f7;
            }
        }
    }
}

.qf-select {
    border-radius: $qfBorderRadius;
    font-size: 12px;
    font-weight: 500;
}

.fieldset-field-label {
    font-weight: bold;
    text-align: right;
}

.crud-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: $qfBorderRadius;
    width: 100%;
    background-color: #A5A5A5;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: $qfBorderRadius;
    padding: $qfBorderRadius;

    &[href] {
        text-decoration: inherit;

        span {
            color: #F7F7F7;
        }
    }
}

.v-input--is-disabled, .v-input--is-readonly {
    &, * {
        cursor: not-allowed;
    }
}

.hint-emphasise {
    .v-text-field__details {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .v-messages__message {
            font-size: 1.25em;
            line-height: 1.25;
            position: relative;
            padding: 9px 12px;
            border: 1px solid #ffeeba;
            border-radius: 4px;
            color: #856404;
            background-color: #fff3cd;
        }
    }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    vertical-align: top;
}